export const $ButtonText = { proceed: '진행하기', next: '다음으로' };

export const $Agreement = {
  agreement1: '가입과 동시에 귀하는 ',
  agreement2: '서비스 약관',
  agreement3: ', ',
  agreement4: '개인정보처리방침',
  agreement5: '에 동의하는 것으로 간주됩니다.',
};

export const $ScreenRotateGuide = {
  screenRotateGuide:
    '두뇌능력 측정 진행을 \n위해서 화면을 가로로 \n돌려주시기 바랍니다.',
};

export const $ScreenFixGuideDialog = {
  fixTitle: '화면을 가로로 유지해 주시기 바랍니다!',
  fixSubTitle:
    '검사 도중 화면을 세로로 돌리면 진행된 기록이 사라질 수 있습니다.',
};

export const $AssessmentExitDialog = {
  exitDialogTitle: '종료하시겠습니까?',
  exitDialogUserInfo: '페이지를 떠날 경우 지금까지 작성한 내용이 사라집니다.',
  exitDialogAssessment:
    '종료할 경우 진행 중인 검사를 처음부터 다시 실시하셔야 합니다.',
  exitDialogYes: '예',
  exitDialogNo: '아니오',
};

export const $UserInfoAgreements = {
  agreementsTitle: '이용약관에 동의해 주세요.',
  agreementsAllAgree: '전체 동의',
  agreementsPp: '(필수) 개인정보처리방침',
  agreementsSpp: '(필수) 민감정보 수집 및 이용 동의',
  agreementsEula: '(필수) 서비스 약관',
  agreementsTp: '(필수) 제 3자 제공 동의',
};

export const $UserInfoCode = {
  codeTitle: '코드를 입력해 주세요.',
  codePlaceholder: '코드를 직접 입력해 주세요',
  codeError: '유효하지 않는 코드입니다!',
};

export const $UserInfoName = {
  nameTitle: '당신의 이름을 입력해 주세요.',
  namePlaceholder: '이름을 직접 입력해 주세요',
};

export const $UserInfoPhone = {
  phoneTitle: '님의 전화번호를 입력해 주세요.',
  phonePlaceholder: '"-" 없이 전화번호를 입력해 주세요',
};

export const $UserInfoBirth = {
  birthTitle: '님의 생년월일을 선택해 주세요.',
  birthYear: '출생 연도',
  birthMonth: '월',
  birthDate: '일',
};

export const $UserInfoGender = {
  genderTitle: '님의 성별을 선택해 주세요.',
  female: '여자',
  male: '남자',
  other: '',
};

export const $UserInfoEduction = {
  educationTitle: '최종 학력을 선택해 주세요.',
};

export const $UserInfoFinish = {
  userInfoFinishTitle: '잘하셨어요!',
  userInfoFinishComment: '그럼 검사를 진행하러 가볼게요.',
};

export const $NumericInfo = {
  numericInfoTitle: '숫자일까?',
  numericInfoComment: '',
};

export const $NumericPracticeCorrect = {
  numericPracticeCorrectTitle: '잘하셨어요.',
  numericPracticeCorrectComment: '이제부터 본 문제를 풀어 보아요.',
};

export const $NumericPracticeWrong = {
  numericPracticeWrongTitle: '아쉬워요.',
  numericPracticeWrongComment: '연습 문제를 다시 풀어 볼까요?',
};

export const $NumericHeaderTitle = {
  numericHeaderTitle: '<보기> 안에 반복되는 숫자가 무엇인가요?',
};

export const $NumericFinish = {
  numericFinishTitle: '숫자일까 검사 끝!',
  numericFinishComment: '다음 검사로 바로 넘어가 볼게요!',
};

export const $StroopInfo = {
  stroopInfoTitle: '개수일까?',
  stroopInfoComment: '',
};

export const $StroopPracticeCorrect = {
  stroopPracticeCorrectTitle: '잘하셨어요.',
  stroopPracticeCorrectComment: '이제부터 본 문제를 풀어 보아요.',
};

export const $StroopPracticeWrong = {
  stroopPracticeWrongTitle: '아쉬워요.',
  stroopPracticeWrongComment: '연습 문제를 다시 풀어 볼까요?',
};

export const $StroopHeaderTitle = {
  stroopHeaderTitle: '<보기> 안에 같은 숫자가 몇 개 있나요?',
};

export const $StroopFinish = {
  stroopFinishTitle: '개수일까? 검사 끝!',
  stroopFinishComment: '다음 검사로 바로 넘어가 볼게요!',
};

export const $RememberInfo = {
  rememberInfoTitle: '기억해 주세요',
  rememberInfoComment: '',
};

export const $RememberHeaderTitle = {
  rememberQuestionHeaderTitle: '아래 그림의 짝을 잘 보고 기억해 보아요.',
  rememberAnswerHeaderTitle: '<보기>와 짝이 되는 그림을 골라 주세요.',
};

export const $RememberCountdown = {
  rememberCountdown: '초 후 문제가 제시됩니다.',
};

export const $RememberExample = {
  rememberExampleTitle: '기억해 주세요',
  rememberExampleComment:
    '지금부터는 앞에서 보신 이미지를 바탕으로 모양의 짝이 되는 그림을 골라 주세요!',
};

export const $RememberPracticeCorrect = {
  rememberPracticeCorrectTitle: '잘하셨어요.',
  rememberPracticeCorrectComment: '이제부터 본 문제를 풀어 보아요.',
};

export const $RememberPracticeWrong = {
  rememberPracticeWrongTitle: '아쉬워요.',
  rememberPracticeWrongComment: '연습 문제를 다시 풀어 볼까요?',
};

export const $RememberTestExample = {
  rememberTestExampleTitle: '기억해 주세요',
  rememberTestExampleComment:
    '지금부터는 앞에서 보신 이미지를 바탕으로 모양의 짝이 되는 그림을 골라 주세요!',
};

export const $RememberFinish = {
  rememberFinishTitle: '기억해 주세요 검사 끝!',
  rememberFinishComment: '다음 검사로 바로 넘어가 볼게요!',
};

export const $MemorizeInfo = {
  memorizeInfoTitle: '혼합테스트',
  memorizeInfoComment: '',
};

export const $MemorizeFinish = {
  memorizeFinishTitle: '수고하셨습니다.',
  memorizeFinishComment: '모든 검사가 종료되었습니다.',
};

export const $MemorizeTitle = {
  memorizeSentenceQuestionTitle:
    '제시되는 문장을 소리 내어 말하면서 외워 주세요.',
  memorizeSentenceAnswerTitle: '조금 전 외웠던 문장을 소리 내어 말해 주세요.',
};

export const $ChooseBiggerTitle = {
  chooseBiggerTitle: '제시되는 두 개의 숫자 중 값이 더 큰 것을 선택해 주세요.',
};

export const $ChooseBigger = {
  chooseBiggerSame: '같다',
};

export const $MemorizeSentence = {
  memorizeSentence11: '김영자씨는, 화요일에, 도서관에서, 선생님을, 만났다.',
  memorizeSentence12: '박성수씨는, 수요일에, 복지관에서, 통장님을, 만났다.',
  memorizeSentence13: '최지영씨는, 목요일에, 우체국에서, 외삼촌을, 만났다.',
  memorizeSentence14: '손정호씨는, 금요일에, 보건소에서, 할머니를, 만났다.',
  memorizeSentence21: '대구에 사는, 이미숙씨는, 시장에서, 참외, 4개를, 샀다.',
  memorizeSentence22: '광주에 사는, 윤민호씨는, 상점에서, 호박, 2개를, 샀다.',
  memorizeSentence23: '인천에 사는, 정수진씨는, 마트에서, 감자, 3개를, 샀다.',
  memorizeSentence24: '강릉에 사는, 김준영씨는, 가게에서, 당근, 2개를, 샀다.',
  memorizeSentence31:
    '초등학생인, 민지는, 동생과, 20분 동안, 학교 운동장에서, 축구를, 했다.',
  memorizeSentence32:
    '중학생인, 진수는, 조카와, 30분 동안, 동네 놀이터에서, 야구를, 했다.',
  memorizeSentence33:
    '고등학생인, 지혜는, 후배와, 40분 동안, 종합 체육관에서, 배구를, 했다.',
  memorizeSentence34:
    '대학생인, 영수는, 친구와, 50분 동안, 한강앞 공원에서, 농구를, 했다.',
};

export const $TotalFinish = {
  totalFinishTitle: '결과 분석 완료',
  totalFinishComment: '결과 분석이 완료되었습니다.',
};

export const $AssessmentInfoButton = {
  startTest: '검사 진행하기',
  startPractice: '연습 문제 풀기',
  practiceAgain: '연습 문제 다시 풀기',
  start: '시작하기',
  startMemorize: '본 문제 풀기',
  continue: '넘어가기',
  analyseData: '결과 분석하기',
  totalFinish: '결과 보러 가기',
};
