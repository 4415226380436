export type tokenType = {
  AccessToken: string;
  AccessTokenExpires: string;
  RefreshToken: string;
  RefreshTokenExpires: string;
};

export const tokenResponseType = {
  success: 'success',
  refresh: 'refresh',
  expired: 'expired',
};

export const appType = {
  aos: 'aos',
  ios: 'ios',
};

export const AnswerResultType = {
  NONE: 'NONE',
  CORRECT: 'CORRECT',
  PASS: 'PASS',
  WRONG: 'WRONG',
  DUPLICATED: 'DUPLICATED',
} as const;

export type DialogType =
  | ''
  | 'main'
  | 'session'
  | 'signInFailed'
  | 'isWaiting'
  | 'help'
  | 'result'
  | 'timeout'
  | 'continue'
  | 'customContinue'
  | 'notification';
