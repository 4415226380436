import { boot } from 'quasar/wrappers';
import VueLogger from 'vuejs3-logger';
import { ILoggerOptions } from 'vuejs3-logger/dist/interfaces/logger-options';
import { LogLevels } from 'vuejs3-logger/dist/enum/log-levels';

export default boot(({ app }) => {
  const options: ILoggerOptions = {
    logLevel: LogLevels.DEBUG,
    isEnabled: true,
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
  };

  app.use(VueLogger, options);
});
