import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/at",
    component: () => import("layouts/AssessmentAndTrainingLayout.vue"),
    redirect: "/at/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("pages/assessmentAndTraining/Home.vue"),
      },
      {
        path: "trainings/:type",
        name: "trainings",
        props: true,
        component: () => import("pages/assessmentAndTraining/Training.vue"),
      },
      {
        path: "setting",
        name: "setting",
        component: () => import("pages/assessmentAndTraining/Setting.vue"),
      },
    ],
  },
  {
    path: "/assessment",
    component: () => import("layouts/AssessmentLayout.vue"),
    redirect: "/assessment/screen-rotate-guide",
    children: [
      {
        path: "screen-rotate-guide",
        name: "screen-rotate-guide",
        component: () => import("pages/assessment/ScreenRotateGuide.vue"),
      },
      {
        path: "user-info/:type",
        name: "user-info",
        props: true,
        component: () => import("pages/assessment/UserInfo.vue"),
      },
      {
        path: "assessment-info/:type/:classification",
        name: "assessment-info",
        props: true,
        component: () => import("pages/assessment/AssessmentInfo.vue"),
      },
      {
        path: "numeric/:type",
        name: "numeric",
        props: true,
        component: () => import("pages/assessment/game/NumericStroop.vue"),
      },
      {
        path: "stroop/:type",
        name: "stroop",
        props: true,
        component: () => import("pages/assessment/game/NumericStroop.vue"),
      },
      {
        path: "remember/countdown/:type",
        name: "remember-countdown",
        props: true,
        component: () => import("pages/assessment/game/RememberCountdown.vue"),
      },
      {
        path: "remember/:type",
        name: "remember",
        props: true,
        component: () => import("pages/assessment/game/Remember.vue"),
      },
      {
        path: "memorize/:type",
        name: "memorize",
        props: true,
        component: () => import("pages/assessment/game/Memorize.vue"),
      },
    ],
  },
  {
    path: "/training",
    component: () => import("layouts/PlainLayout.vue"),
    children: [
      {
        path: "custom",
        name: "custom",
        component: () => import("pages/training/TrainingChat.vue"),
      },
      {
        path: "info/:trainingId/:isCustom?",
        name: "training-info",
        props: true,
        component: () => import("pages/training/TrainingInfo.vue"),
      },
      {
        path: ":trainingId/:trainingLevel",
        name: "training",
        props: true,
        component: () => import("pages/training/TrainingChat.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("layouts/PlainLayout.vue"),
    children: [
      {
        path: "",
        name: "splash",
        meta: { noAuth: true, gotoMain: true },
        component: () => import("pages/assessmentAndTraining/Splash.vue"),
      },
      {
        path: "entry",
        name: "entry",
        meta: { noAuth: true },
        component: () => import("pages/assessmentAndTraining/Entry.vue"),
      },
      {
        path: "login",
        name: "login",
        meta: { noAuth: true, gotoMain: true },
        component: () => import("pages/assessmentAndTraining/Login.vue"),
      },
      {
        path: "login/organization",
        name: "organization-login",
        meta: { noAuth: true, gotoMain: true },
        component: () =>
          import("pages/assessmentAndTraining/Login/OrganizationLogin.vue"),
      },
      {
        path: "login/searchProfile/:uuid",
        name: "search-profile",
        meta: { noAuth: true, gotoMain: true },
        component: () =>
          import("pages/assessmentAndTraining/Login/SearchProfile.vue"),
      },
      {
        path: "login/auth-credentials-input/:uuid",
        name: "auth-credentials-input",
        meta: { noAuth: true, gotoMain: true },
        component: () =>
          import("pages/assessmentAndTraining/Login/AuthCredentialsInput.vue"),
      },
      {
        path: "login/pick-account",
        name: "pick-account",
        meta: { noAuth: true, gotoMain: true },
        component: () =>
          import("pages/assessmentAndTraining/Login/PickAccount.vue"),
      },
      {
        path: "login/haii",
        name: "haii-login",
        meta: { noAuth: true, gotoMain: true },
        component: () => import("pages/assessmentAndTraining/HaiiLogin.vue"),
      },
      {
        path: "expired",
        name: "expired",
        meta: { noAuth: true },
        component: () => import("pages/assessmentAndTraining/Expired.vue"),
      },
      {
        path: "withdraw",
        name: "withdraw",
        props: true,
        meta: { noAuth: true },
        component: () => import("pages/assessmentAndTraining/Withdraw.vue"),
      },
      {
        path: "customer-center",
        name: "customer-center",
        component: () =>
          import("pages/assessmentAndTraining/CustomerCenter.vue"),
      },
      {
        path: "app-version",
        name: "app-version",
        component: () => import("pages/assessmentAndTraining/AppVersion.vue"),
      },
      {
        path: "info-source",
        name: "info-source",
        component: () => import("pages/assessmentAndTraining/InfoSource.vue"),
      },
    ],
  },
  {
    path: "/404",
    meta: { noAuth: true },
    component: () => import("layouts/PlainLayout.vue"),
    children: [
      {
        path: "",
        name: "notFound",
        meta: { noAuth: true },
        component: () => import("pages/common/NotFound.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)*",
    redirect: "/404",
  },
];

export default routes;
